import React from 'react';
import cnj from '../utils/cnj';
import type { TextProps } from '../Typography';
import Typography from '../Typography';
import Flex from '../Flex';
import type { colorsKeys } from '../helpers/theme';
import classes from './index.module.scss';

interface BadgeProps {
  text?: string | number;
  background?: colorsKeys;
  borderColor?: colorsKeys;
  disabled?: boolean;
  disabledColor?: colorsKeys;
  hoverColor?: colorsKeys;
  className?: string;
  textProps?: TextProps;
}
const Badge: React.FC<BadgeProps> = ({
  text,
  background = 'error',
  borderColor = 'background',
  disabled,
  disabledColor = 'disabledError',
  hoverColor,
  className,
  textProps,
}) => {
  return (
    <Flex
      as="span"
      className={cnj(
        classes.badgeRoot,
        classes[`backgroundColor-${background}`],
        classes[`borderColor-${borderColor}`],
        className,
        disabled && classes.disabledStyle,
        disabled && classes[`backgroundColor-${disabledColor}`]
      )}
    >
      <Typography
        textAlign="center"
        font="700"
        color="white"
        size={12}
        height={10}
        {...textProps}
      >
        {text}
      </Typography>
    </Flex>
  );
};

export default Badge;
