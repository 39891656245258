import React, { forwardRef } from 'react';
import cnj from '../utils/cnj';
import type { IconFontType, IconProps } from '../Icon';
import Icon from '../Icon';
import type { colorsKeys } from '../helpers/theme';
import Badge from '../Badge';
import Link from '../Link';
import type { LinkProps } from '@lobox/utils';
import Flex from '../Flex';
import classes from './IconButton.module.scss';

const sizeMapperTable = {
  sm: { iconSize: 15, wrapperSize: 24 },
  sm16: { iconSize: 16, wrapperSize: 24 },
  sm18: { iconSize: 18, wrapperSize: 24 },
  sm20: { iconSize: 20, wrapperSize: 24 },
  sm22: { iconSize: 22, wrapperSize: 24 },
  md: { iconSize: 20, wrapperSize: 32 },
  md15: { iconSize: 15, wrapperSize: 32 },
  md18: { iconSize: 18, wrapperSize: 32 },
  md20: { iconSize: 20, wrapperSize: 32 },
  md24: { iconSize: 24, wrapperSize: 32 },
  lg: { iconSize: 20, wrapperSize: 36 },
  mlg: { iconSize: 20, wrapperSize: 38 },
  xl: { iconSize: 24, wrapperSize: 48 },
};

const variantBorderRadius = {
  circle: 100,
  rectangle: 4,
};

const colorSchemaMapperTable = {
  primary: {
    background: 'background',
    hover: 'hoverPrimary',
    iconColor: 'primaryText',
    focus: 'smoke2',
  },
  disabledGray: {
    background: 'background',
    hover: 'hoverPrimary',
    iconColor: 'disabledGrayDark_disabledGray',
    focus: 'smoke2',
  },
  transparent1: {
    background: 'transparent',
    hover: 'hover75_techGray_20',
    iconColor: 'primaryText',
    focus: 'darkSecondary_hoverGray15',
  },
  'transparent-white': {
    background: 'transparent',
    hover: 'transparent',
    iconColor: 'white',
    focus: 'transparent',
  },
  'primary-light': {
    background: 'lightGray',
    hover: 'hover_2',
    iconColor: 'graphene',
  },
  'primary-blue': {
    background: 'brand',
    hover: 'trench',
    iconColor: 'white',
  },
  secondary: {
    background: 'background',
    hover: 'hoverPrimary',
    iconColor: 'thirdText',
    focus: 'smoke2',
  },
  secondary2: {
    background: 'transparent',
    hover: 'hoverPrimary',
    iconColor: 'secondaryText',
    focus: 'smoke2',
  },
  'secondary-transparent': {
    background: 'transparent',
    hover: 'hoverPrimary',
    iconColor: 'primaryText',
    focus: 'smoke2',
  },
  tertiary: {
    background: 'background',
    hover: 'hoverPrimary',
    iconColor: 'brand',
    focus: 'smoke2',
  },
  'tertiary-transparent': {
    background: 'transparent',
    hover: 'hoverPrimary',
    iconColor: 'brand',
    focus: 'smoke2',
  },
  transparent: {
    background: 'transparent',
    hover: 'hoverPrimary',
    iconColor: 'primaryText',
    focus: 'smoke2',
  },
  transparentSmokeCoal: {
    background: 'transparent',
    hover: 'hoverPrimary',
    iconColor: 'smoke_coal',
    focus: 'smoke2',
  },
  transparent2: {
    background: 'transparent',
    hover: 'hoverPrimary',
    iconColor: 'disabledGrayDark_gray',
    focus: 'smoke2',
  },
  dark: {
    background: 'popOverBg',
    hover: 'hover_75',
    iconColor: 'disabledGray',
    focus: 'muteMidGray',
  },
  error: {
    background: 'lightError',
    hover: 'borderError',
    iconColor: 'error',
    focus: 'borderError',
  },
  warning: {
    background: 'transparent',
    hover: 'warning_5',
    iconColor: 'warning',
    focus: 'warning_5',
  },
  success: {
    background: 'transparent',
    hover: 'success_5',
    iconColor: 'success',
    focus: 'warning_5',
  },
  tag: {
    background: 'transparent',
    hover: 'tagHover',
    iconColor: 'colorIconSeventh',
    focus: 'tagHover',
  },
  modalHeaderIcon: {
    background: 'transparent',
    hover: 'hoverThird',
    iconColor: 'thirdText',
    focus: 'colorIconThird',
  },
  gray: {
    background: 'background',
    hover: 'hoverPrimary',
    iconColor: 'primaryDisabledText',
    focus: 'smoke2',
  },
  graySecondary: {
    background: 'backgroundIconSecondary',
    hover: 'hoverPrimary',
    iconColor: 'primaryText',
    focus: 'smoke2',
  },
  'semi-transparent': {
    background: 'brand_10',
    hover: 'brand_20',
    iconColor: 'brand',
    focus: 'smoke2',
  },
  'orange-semi-transparent': {
    background: 'pendingOrange_10',
    hover: 'pendingOrange_50',
    iconColor: 'pendingOrange',
    focus: 'pendingOrange',
  },
  backgroundIconSecondary: {
    background: 'backgroundIconSecondary',
    hover: 'hoverThird',
    iconColor: 'primaryText',
    focus: 'smoke2',
  },
  bottomBarIcon: {
    background: 'background',
    hover: 'hoverPrimary',
    iconColor: 'ninthText',
    focus: 'smoke2',
  },
  smoke_coal: {
    background: 'backgroundIconSecondary',
    hover: 'hoverThird',
    iconColor: 'smoke_coal',
    focus: 'smoke2',
  },
  white: {
    background: 'backgroundIconSecondary',
    hover: 'hoverThird',
    iconColor: 'white',
    focus: 'smoke2',
  },
};

const defaultSchema = 'primary';

type SchemaItem = {
  background: colorsKeys;
  hover: colorsKeys;
  color: colorsKeys;
  iconColor: colorsKeys;
  focus: colorsKeys;
};
export type IconButtonColorSchema = keyof typeof colorSchemaMapperTable;

export interface IconButtonProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => any;
  htmlId?: string;
  to?: LinkProps['to'];
  href?: string;
  name: string;
  size?: keyof typeof sizeMapperTable;
  colorSchema?: IconButtonColorSchema;
  type?: IconFontType;
  className?: string;
  iconClassName?: string;
  disabled?: boolean;
  variant?: 'circle' | 'rectangle';
  badgeNumber?: number | string;
  tooltip?: string;
  tPlacement?: string;
  icon?: React.ReactNode;
  iconProps?: IconProps;
  noHover?: boolean;
  target?: string;
  badgeClassName?: string;
}

const IconButton = (
  {
    onClick,
    htmlId,
    to,
    href,
    name,
    size = 'md',
    colorSchema = defaultSchema,
    type,
    className,
    iconClassName,
    disabled,
    variant = 'circle',
    badgeNumber,
    tooltip,
    tPlacement,
    iconProps,
    noHover,
    target,
    badgeClassName,
  }: IconButtonProps,
  ref: any
) => {
  const { iconColor, background, hover, focus } =
    (colorSchemaMapperTable[colorSchema] as SchemaItem) || {};
  const { iconSize, wrapperSize } = sizeMapperTable[size] || {};

  const Wrapper = to ? Link : Flex;
  const wrapperProps = to
    ? { to }
    : href
      ? { href, as: 'a', target }
      : { onClick };

  return (
    // @ts-ignore
    <Wrapper
      data-tip={tooltip}
      data-place={tPlacement}
      ref={ref}
      {...wrapperProps}
      className={cnj(
        classes.iconButtonWrap,
        classes[`backgroundColor-${background}`],
        classes[`backgroundColorHover-${hover}`],
        classes[`backgroundColorFocus-${focus}`],
        variant === 'rectangle' && classes.rectangle,
        (noHover || disabled) && classes[`noHover-${background}`],
        className
      )}
      style={{
        width: wrapperSize,
        height: wrapperSize,
        minWidth: wrapperSize,
        minHeight: wrapperSize,
      }}
      id={htmlId}
    >
      <Icon
        {...{
          name,
          size: iconSize,
          type,
          color: iconColor,
          className: cnj(disabled && classes.disabledStyle, iconClassName),
          ...(iconProps || {}),
        }}
      />
      {!!badgeNumber && (
        <Badge
          className={cnj(classes.badge, badgeClassName)}
          disabled={disabled}
          text={badgeNumber}
        />
      )}
    </Wrapper>
  );
};

export default forwardRef<any, IconButtonProps>(IconButton);
